import type { ApiFetch } from './main'
import type {
  AuthClinic,
  Clinic,
  ClinicSettings,
  ClinicUsage,
  SMSNotificationTemplate,
} from '~/types/clinic'

export interface ClinicApi {
  getClinicsFromCurrentUser: () => Promise<Clinic[]>
  registerClinic: (
    data: { name: string },
    sessionId: string,
  ) => Promise<AuthClinic>
  updateClinic: (data: Partial<Clinic>) => Promise<Clinic>
  updateClinicLogo: (data: FormData) => Promise<any>
  updateClinicSettings: (data: ClinicSettings) => Promise<ClinicSettings>
  getClinicSMSTemplates: () => Promise<SMSNotificationTemplate[]>
  updateClinicSMSTemplate: (
    id: string,
    data: Partial<SMSNotificationTemplate>,
  ) => Promise<SMSNotificationTemplate>
  getClinicUsage: (month: string, year: string) => Promise<ClinicUsage>
  deleteClinic: () => Promise<any>
}

export default (apiFetch: ApiFetch): ClinicApi => ({
  // IMPORTANT: There is a difference between /clinic and /clinics

  // Public API - /clinics
  getClinicsFromCurrentUser() {
    return apiFetch(`/clinics`)
  },

  registerClinic(data, sessionId: string) {
    return apiFetch(`/clinics?session_id=${sessionId}`, {
      method: 'POST',
      body: data,
    })
  },

  // Inside Tenant - /clinic
  updateClinic(data) {
    return apiFetch(`/clinic`, {
      method: 'PATCH',
      body: data,
    })
  },

  // Why this endpoint is 'clinics' and not 'clinic'?
  // When uploading to S3, we determine the bucket based on the tenant schema name, however, we are requesting the image from /users/me and other public endpoints, which are not tenant-aware. 'clinics' is a public endpoint, therefore, we can solve this issue by using 'clinics' instead of 'clinic' for the endpoint.
  updateClinicLogo(data) {
    return apiFetch(`/clinics/upload-logo`, {
      method: 'POST',
      body: data,
    })
  },

  updateClinicSettings(data) {
    return apiFetch(`/clinic/settings`, {
      method: 'PATCH',
      body: data,
    })
  },

  getClinicSMSTemplates() {
    return apiFetch(`/clinic/sms-templates`)
  },

  updateClinicSMSTemplate(id, data) {
    return apiFetch(`/clinic/sms-templates/${id}`, {
      method: 'PATCH',
      body: data,
    })
  },

  getClinicUsage(month, year) {
    return apiFetch(`/clinic/usage?month=${month}&year=${year}`)
  },

  deleteClinic() {
    return apiFetch(`/clinic`, {
      method: 'DELETE',
    })
  },
})
