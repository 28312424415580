import revive_payload_client_doxcNfcq7z from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_qkmdb4j6fvxhpu45m33fgbdl5q/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_fqcjMgl9XZ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_qkmdb4j6fvxhpu45m33fgbdl5q/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_fFjtvUoFf1 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_qkmdb4j6fvxhpu45m33fgbdl5q/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_78YQLHjNWk from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_qkmdb4j6fvxhpu45m33fgbdl5q/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_0rpCMkBK3y from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_qkmdb4j6fvxhpu45m33fgbdl5q/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_apu6Lmb0oZ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_qkmdb4j6fvxhpu45m33fgbdl5q/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_9UczxfS02j from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_qkmdb4j6fvxhpu45m33fgbdl5q/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_6fuhnImRWW from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.3_typescript@5.6.2_vue@3.5.6_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_ZALq1SwliY from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_qkmdb4j6fvxhpu45m33fgbdl5q/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_79LXteTuJf from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_magicast@0.3.5_rollup@4.21.3_vite@5.4.5_@types+node@22.5.5_sass@1.78.0_terser_2omiamtblcsdjgjtex7adj7wea/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_5q2ghNV08v from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_magicast@0.3.5_rollup@4.21.3_vite@5.4.5_@types+node@22.5.5_sass@1.78.0_terser_2omiamtblcsdjgjtex7adj7wea/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_OPPbozCSNW from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_magicast@0.3.5_rollup@4.21.3_vite@5.4.5_@types+node@22.5.5_sass@1.78.0_terser_2omiamtblcsdjgjtex7adj7wea/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_mbRGKSAr4j from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.21.3_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_5uFmalkcmo from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.5.1_magicast@0.3.5_rollup@4.21.3_vite@5.4.5_@types+node@22.5.5_sass@1.78.0_terse_hs4iimf3jumqhwhbnt2h3ybpgm/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import formkitPlugin_pZqjah0RUG from "/opt/buildhome/repo/.nuxt/formkitPlugin.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/opt/buildhome/repo/.nuxt/pwa-icons-plugin.ts";
import pwa_client_UAhwHNao4Z from "/opt/buildhome/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_@vite-pwa+assets-generator@0.2.6_magicast@0.3.5_rollup@4.21.3_vite@5.4._u7kc2grob76k4c7dbxsyfk36jy/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import _01_api_GIiciWaEWX from "/opt/buildhome/repo/plugins/01.api.ts";
import _02_nuxtClientInit_client_42PBK3TMYu from "/opt/buildhome/repo/plugins/02.nuxtClientInit.client.ts";
import dayjs_pGiXRjcsJO from "/opt/buildhome/repo/plugins/dayjs.ts";
import sentry_client_shVUlIjFLk from "/opt/buildhome/repo/plugins/sentry.client.ts";
import sweetalert_client_3q8rAPeAim from "/opt/buildhome/repo/plugins/sweetalert.client.ts";
export default [
  revive_payload_client_doxcNfcq7z,
  unhead_fqcjMgl9XZ,
  router_fFjtvUoFf1,
  payload_client_78YQLHjNWk,
  navigation_repaint_client_0rpCMkBK3y,
  check_outdated_build_client_apu6Lmb0oZ,
  chunk_reload_client_9UczxfS02j,
  plugin_vue3_6fuhnImRWW,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ZALq1SwliY,
  slideovers_79LXteTuJf,
  modals_5q2ghNV08v,
  colors_OPPbozCSNW,
  plugin_client_mbRGKSAr4j,
  plugin_5uFmalkcmo,
  formkitPlugin_pZqjah0RUG,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_UAhwHNao4Z,
  _01_api_GIiciWaEWX,
  _02_nuxtClientInit_client_42PBK3TMYu,
  dayjs_pGiXRjcsJO,
  sentry_client_shVUlIjFLk,
  sweetalert_client_3q8rAPeAim
]