export interface ApiResponse<T> {
  count: number
  items: T[]
}

export interface WebsocketMessage {
  type: string
  code: WebsocketEvent
  message: string
}

export enum WebsocketEvent {
  HEARTBEAT = 'heartbeat',
  APPOINTMENT_CHANGED = 'appointment_changed',
  PATIENT_CHANGED = 'patient_changed',
  STOCK_CHANGED = 'stock_changed',
  INVOICE_CHANGED = 'invoice_changed',
  CLINIC_CHANGED = 'clinic_changed',
  STAFF_CHANGED = 'staff_changed',
  NOTIFICATION_CHANGED = 'notification_changed',
  REMINDER_CHANGED = 'reminder_changed',
}

export enum Permission {
  VIEW_INVOICE = 'view_invoice',
  ADD_INVOICE = 'add_invoice',
  VIEW_STOCK = 'view_stockitem',
  ADD_STOCK = 'add_stockitem',
  permission = 'permission',
}

export enum PermissionReadable {
  add_stockitem = 'Editar Stock',
  add_invoice = 'Editar Faturas',
  view_stockitem = 'Ver Stock',
  view_invoice = 'Ver Faturas',
}
