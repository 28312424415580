import type { ApiFetch } from './main'
import type { AddComment, Appointment, Comment } from '~/types/calendar'
import type { ApiResponse } from '~/types/misc'

export interface AppointmentsAPI {
  getAppointments: (
    filter?: string,
    limit?: number,
    include_pictures?: boolean,
  ) => Promise<ApiResponse<Appointment>>
  addAppointment: (data: Partial<Appointment>) => Promise<any>
  getSingleAppointment: (id: string) => Promise<Appointment>
  getPatientAppointments: (
    id: string,
    limit?: number,
    filter?: string,
  ) => Promise<ApiResponse<Appointment>>
  updateAppointment: (id: string, data: Partial<Appointment>) => Promise<any>
  sendCancelAppointmentSMS: (id: string) => Promise<any>
  deleteAppointment: (id: string, reason: string) => Promise<any>
  deletePermanentlyAppointment: (id: string) => Promise<any>
  getAppointmentComments: (id: string) => Promise<Comment[]>
  addAppointmentComment: (id: string, data: AddComment) => Promise<any>
  updateAppointmentComment: (
    id: string,
    commentId: string,
    data: AddComment,
  ) => Promise<any>
  deleteAppointmentComment: (id: string, commentId: string) => Promise<any>
}

export default (apiFetch: ApiFetch): AppointmentsAPI => ({
  getAppointments(filter, limit = 0, include_pictures = false) {
    let url = '/appointments?'

    if (filter) {
      url += `${filter}&`
    }

    if (limit) {
      url += `limit=${limit}&`
    }

    if (include_pictures) {
      url += `include_pictures=true&`
    }

    // Remove the trailing '&' or '?' if no parameters were added
    url = url.endsWith('&') || url.endsWith('?') ? url.slice(0, -1) : url

    return apiFetch(url)
  },

  addAppointment(data) {
    return apiFetch(`/appointments`, {
      method: 'POST',
      body: data,
    })
  },

  getSingleAppointment(id) {
    return apiFetch(`/appointments/${id}`)
  },

  updateAppointment(id, data) {
    return apiFetch(`/appointments/${id}`, {
      method: 'PATCH',
      body: data,
    })
  },

  sendCancelAppointmentSMS(id) {
    return apiFetch(`/appointments/${id}/cancel`, {
      method: 'PATCH',
      body: { sms_cancelled: true },
    })
  },

  deleteAppointment(id, reason: string) {
    return apiFetch(`/appointments/${id}/cancel`, {
      method: 'PATCH',
      body: { cancellation_reason: reason },
    })
  },

  deletePermanentlyAppointment(id) {
    return apiFetch(`/appointments/${id}`, {
      method: 'DELETE',
    })
  },

  getPatientAppointments(id, limit, filter) {
    let url = ''

    if (id) {
      url += `patient=${id}&`
    }

    if (filter) {
      url += `${filter}&`
    }

    // Remove the trailing '&' or '?' if no parameters were added
    url = url.endsWith('&') || url.endsWith('?') ? url.slice(0, -1) : url

    return this.getAppointments(url, limit)
  },

  // Appointment Comments
  getAppointmentComments(id) {
    return apiFetch(`/appointments/${id}/comments`)
  },

  addAppointmentComment(id, data) {
    return apiFetch(`/appointments/${id}/comments`, {
      method: 'POST',
      body: data,
    })
  },

  updateAppointmentComment(id, commentId, data) {
    return apiFetch(`/appointments/${id}/comments/${commentId}`, {
      method: 'PATCH',
      body: data,
    })
  },

  deleteAppointmentComment(id, commentId) {
    return apiFetch(`/appointments/${id}/comments/${commentId}`, {
      method: 'DELETE',
    })
  },
})
