import Swal from 'sweetalert2'

declare module '#app' {
  interface NuxtApp {
    $swal: typeof Swal
  }
}

const options = {
  confirmButtonColor: '#d80027',
  showCancelButton: true,
  cancelButtonText: 'Cancelar',
  customClass: {
    popup: 'rounded-lg shadow-xl !p-0',
    container: '!bg-gray-200/75 dark:!bg-gray-800/75',
    icon: 'mb-0 mt-8',
    title: 'text-2xl font-bold text-gray-800',
    actions:
      'bg-gray-50 px-4 py-3 sm:px-6 w-full justify-end rounded-b-lg mt-8',
    confirmButton:
      'focus:outline-none focus-visible:outline-0 disabled:cursor-not-allowed disabled:opacity-75 flex-shrink-0 font-medium !rounded-md !text-sm gap-x-2.5 px-3.5 py-2.5 shadow-sm !text-white dark:!text-gray-900 !bg-red-500 hover:!bg-red-600 disabled:!bg-red-500 dark:!bg-red-400 dark:hover:!bg-red-500 dark:disabled:!bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500 dark:focus-visible:outline-red-400 inline-flex items-center transition-colors duration-300 !bg-none',
    cancelButton:
      'focus:outline-none focus-visible:outline-0 disabled:cursor-not-allowed disabled:opacity-75 flex-shrink-0 font-medium !rounded-md !text-sm gap-x-2.5 px-3.5 py-2.5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 !text-gray-700 dark:!text-gray-200 !bg-gray-50 hover:!bg-gray-100 disabled:!bg-gray-50 dark:!bg-gray-800 dark:hover:!bg-gray-700/50 dark:disabled:!bg-gray-800 focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400 inline-flex items-center transition-colors duration-300 !bg-none',
  },
}

export default defineNuxtPlugin({
  parallel: true,
  async setup(nuxtApp) {
    nuxtApp.provide('swal', Swal.mixin(options))
  },
})
